import React from 'react';

export default function MapMarker(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path
        d="M10.9951 22.17C11.2863 22.3844 11.6384 22.5001 12.0001 22.5001C12.3617 22.5001 12.7138 22.3844 13.0051 22.17C15.0445 20.6729 16.7025 18.7162 17.8447 16.4587C18.9868 14.2011 19.5809 11.7063 19.5788 9.17625V8.86875C19.3951 -0.95625 4.60881 -0.95625 4.42506 8.86875V9.17625C4.42254 11.7059 5.01607 14.2006 6.15753 16.4581C7.29899 18.7156 8.95627 20.6725 10.9951 22.17ZM12.0001 5.25C12.7417 5.25 13.4668 5.46993 14.0834 5.88199C14.7001 6.29404 15.1808 6.87972 15.4646 7.56494C15.7484 8.25016 15.8227 9.00416 15.678 9.73159C15.5333 10.459 15.1762 11.1272 14.6517 11.6517C14.1273 12.1761 13.4591 12.5333 12.7316 12.6779C12.0042 12.8226 11.2502 12.7484 10.565 12.4645C9.87977 12.1807 9.2941 11.7001 8.88205 11.0834C8.46999 10.4667 8.25006 9.74168 8.25006 9C8.25006 8.00544 8.64514 7.05161 9.34841 6.34835C10.0517 5.64509 11.0055 5.25 12.0001 5.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
