import React from 'react';

export default function Linkedin(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={24} height={24} {...props}>
      <path
        d="M13.995 16.9497H7.005C6.44995 16.9497 6 17.3997 6 17.9547V40.9947C6 41.5498 6.44995 41.9997 7.005 41.9997H13.995C14.55 41.9997 15 41.5498 15 40.9947V17.9547C15 17.3997 14.55 16.9497 13.995 16.9497Z"
        fill="currentColor"
      />
      <path
        d="M34.965 16.7099C33.6629 16.6605 32.3648 16.8831 31.1535 17.3634C29.9422 17.8438 28.8443 18.5714 27.93 19.4999C27.8621 19.5718 27.7742 19.6218 27.6776 19.6433C27.5811 19.6648 27.4803 19.6569 27.3883 19.6206C27.2962 19.5842 27.2172 19.5212 27.1614 19.4395C27.1055 19.3578 27.0755 19.2613 27.075 19.1624V17.9474C27.075 17.8154 27.049 17.6847 26.9985 17.5628C26.948 17.4408 26.874 17.33 26.7807 17.2367C26.6873 17.1434 26.5765 17.0694 26.4546 17.0189C26.3327 16.9684 26.202 16.9424 26.07 16.9424H18.9975C18.8646 16.9423 18.733 16.9689 18.6105 17.0204C18.488 17.0719 18.377 17.1474 18.2841 17.2424C18.1912 17.3375 18.1182 17.4501 18.0694 17.5737C18.0206 17.6974 17.997 17.8295 18 17.9624C18.06 22.2299 18 36.9974 18 40.9949C17.998 41.2581 18.1002 41.5115 18.2842 41.6998C18.4682 41.888 18.7193 41.9959 18.9825 41.9999H26.0775C26.3441 41.9999 26.5997 41.894 26.7882 41.7055C26.9766 41.517 27.0825 41.2614 27.0825 40.9949V28.4474C27.023 27.7587 27.0891 27.0649 27.2775 26.3999C27.5785 25.5035 28.1543 24.7246 28.923 24.174C29.6917 23.6234 30.6144 23.3289 31.56 23.3324C34.6425 23.3324 36.06 25.6499 36.06 29.0399V40.9949C36.0599 41.2563 36.1616 41.5075 36.3437 41.6951C36.5257 41.8827 36.7737 41.9921 37.035 41.9999H43.995C44.127 41.9999 44.2577 41.9739 44.3796 41.9234C44.5015 41.8729 44.6123 41.7988 44.7057 41.7055C44.799 41.6122 44.873 41.5014 44.9235 41.3795C44.974 41.2575 45 41.1268 45 40.9949V28.0724C45 20.3099 40.5825 16.7099 34.965 16.7099Z"
        fill="currentColor"
      />
      <path
        d="M10.425 14.1525H10.5C13.29 14.1525 15 12.3525 15 10.0725C15 7.7925 13.2675 6 10.5 6C7.7325 6 6 7.755 6 10.08C6 12.405 7.74 14.1525 10.425 14.1525Z"
        fill="currentColor"
      />
    </svg>
  );
}
