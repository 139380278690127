import React from 'react';

export default function Instagram(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={24} height={24} {...props}>
      <path
        d="M15.4875 42H32.5125C35.0528 41.9644 37.476 40.9257 39.2533 39.1104C41.0307 37.295 42.0181 34.8505 42 32.31V15.69C42.0181 13.1495 41.0307 10.705 39.2533 8.88964C37.476 7.07433 35.0528 6.03558 32.5125 6H15.4875C12.9472 6.03558 10.524 7.07433 8.74666 8.88964C6.96928 10.705 5.98193 13.1495 6 15.69V32.31C5.98193 34.8505 6.96928 37.295 8.74666 39.1104C10.524 40.9257 12.9472 41.9644 15.4875 42ZM34.5 12C34.9468 11.9911 35.3862 12.1154 35.7621 12.3571C36.1379 12.5989 36.4333 12.9471 36.6105 13.3573C36.7878 13.7676 36.8388 14.2213 36.7571 14.6607C36.6754 15.1001 36.4648 15.5052 36.152 15.8244C35.8392 16.1436 35.4384 16.3624 35.0008 16.4529C34.5631 16.5434 34.1084 16.5016 33.6947 16.3327C33.2809 16.1638 32.9268 15.8755 32.6775 15.5046C32.4283 15.1337 32.2951 14.6969 32.295 14.25C32.2949 13.661 32.5257 13.0954 32.938 12.6747C33.3503 12.254 33.9111 12.0118 34.5 12ZM24 15C25.7875 14.9628 27.5457 15.4588 29.0502 16.4248C30.5547 17.3908 31.7374 18.783 32.4474 20.4239C33.1574 22.0648 33.3626 23.8801 33.0368 25.6381C32.711 27.396 31.869 29.0172 30.6181 30.2947C29.3672 31.5722 27.7642 32.4481 26.0134 32.8109C24.2627 33.1736 22.4435 33.0067 20.7881 32.3313C19.1326 31.656 17.7157 30.5029 16.7183 29.0191C15.7208 27.5352 15.1879 25.7879 15.1875 24C15.1635 21.6382 16.0783 19.3635 17.7308 17.6758C19.3832 15.9882 21.6382 15.0257 24 15Z"
        fill="currentColor"
      />
    </svg>
  );
}
