import React from 'react';

import MapMarker from '@pagerland/icons/src/line/MapMarker';
import textToMultiline from '@pagerland/common/src/utils/textToMultiline';
import Phone from '@pagerland/icons/src/line/Phone';
import Envelope from '@pagerland/icons/src/line/Envelope';
import FacebookF from '@pagerland/icons/src/line/FacebookF';
import Instagram from '@pagerland/icons/src/line/Instagram';
import Youtube from '@pagerland/icons/src/line/Youtube';
import Cog from '@pagerland/icons/src/line/Cog';
import Figma from '@pagerland/icons/src/line/Figma';
import LifeRing from '@pagerland/icons/src/line/LifeRing';

import Typography from '@pagerland/common/src/components/Typography';
import Img from '@pagerland/common/src/components/Img';

import { smoothLinkProps } from '@pagerland/common/src/utils';

import maschable from './assets/brands/maschable.svg';
import extremetech from './assets/brands/extremetech.svg';
import techbragins from './assets/brands/techbragins.svg';
import envato from './assets/brands/envato.svg';
import geek from './assets/brands/geek.svg';

import features1 from './assets/features.svg';
import features2 from './assets/features-2.svg';
import features3 from './assets/features-3.svg';
import features4 from './assets/features-4.svg';

import Screenshot from './assets/screenshot.jpg';
import Screenshot2x from './assets/screenshot@2x.jpg';
import Avatar from './assets/avatar.jpg';
import Avatar2x from './assets/avatar@2x.jpg';

export default {
  title: 'WebApp',
  navbar: {
    links: [
      {
        to: '',
        label: 'Home',
      },
      {
        to: 'about',
        label: 'Sobre',
      },
      {
        to: 'features',
        label: 'Funcionalidades',
      },
      // {
      //   to: 'screens',
      //   label: 'Telas',
      // },
      // {
      //   to: 'clients',
      //   label: 'Clientes',
      // },
      // {
      //   to: 'pricing',
      //   label: 'Preços',
      // },
      {
        to: 'contact',
        label: 'Contato',
      },
    ],
    actions: [
      {
        href:
          'mailto:abordo@dex.company',
        label: 'Entre em Contato',
      },
    ],
  },
  welcome: {
    title: [
      'Bem vindo ',
      <Typography as="span" variant="h1" color="primary">
        Abordo
      </Typography>,
      '!'
    ],
    text:[
      'Aumente as vendas de passagens por canais digitais da sua empresa com o Abordo.',
      '\n',
      <Typography as="span" variant="body1" color="primary">
        Coloque no ar, em poucos dias, um site e aplicativo para fazer as vendas on-line da sua empresa de transporte rodoviário de passageiros.
      </Typography>,
    ]
    ,
    actions: [
      {
        label: 'Saiba Mais',
        to: 'about',
        ...smoothLinkProps,
        variant: 'secondary',
        size:'large'
      },
      {
        label: 'Entre em Contato',
        href: 'mailto:abordo@dex.company',
        variant: 'primary',
        size:'large'
      },
    ],
  },
  brands: [
    {
      src: maschable,
      alt: 'Maschable',
      LinkProps: {
        href: 'https://mypags.app',
        title: 'Maschable',
      },
    },
    {
      src: extremetech,
      alt: 'Extremetech',
      LinkProps: {
        href: 'https://mypags.app',
        title: 'Extremetech',
      },
    },
    {
      src: techbragins,
      alt: 'Techbragins',
      LinkProps: {
        href: 'https://mypags.app',
        title: 'Techbragins',
      },
    },
    {
      src: envato,
      alt: 'Envato',
      LinkProps: {
        href: 'https://mypags.app',
        title: 'Envato',
      },
    },
    {
      src: geek,
      alt: 'Geek',
      LinkProps: {
        href: 'https://mypags.app',
        title: 'Geek',
      },
    },
  ],
  about: {
    sections: [
      {
        IconWrapperProps: {
          bg: 'primary',
        },
        title: 'Plug `n Play',
        text:
          'Não é necessário gastar tempo desenvolvendo integrações, telas e funcionalidades. Basta configurar a sua identidade visual e integrações e ligar os cabos para começar vender!',
        icon: Cog,
      },
      {
        IconWrapperProps: {
          bg: 'secondary',
        },
        title: '100% Customizável',
        text:
          'Nós sabemos que cada empresa tem suas necessidades específicas, e por isso também oferecemos o serviço de customizações através de  Serviços Profissionais.',
        icon: Figma,
      },
      {
        IconWrapperProps: {
          bg: 'tertiary',
        },
        title: 'Business Inteligence',
        text:
          'Na era digital, os dados são cada dia mais importantes. Por isso, com o Abordo, a sua empresa tem acesso a todos os dados de clientes e pedidos transacionados via API ou painel administrativo.',
        icon: LifeRing,
      },
      {
        IconWrapperProps: {
          bg: 'primary',
        },
        title: 'Escalável',
        text:
          'Não se preocupe com a disponibilidade. Com toda infra-estrutura na nuvem da AWS, o Abordo é uma plataforma totalmente escalável, e preparado para suportar os momentos de pico.',
        icon: LifeRing,
      },
      {
        IconWrapperProps: {
          bg: 'primary',
        },
        title: 'Flexível',
        text:
          'Nosso modelo de negócios é aberto e transparente. Sem custos ou taxas escondidas, você só paga pelo que consumir.',
        icon: LifeRing,
      },
      {
        IconWrapperProps: {
          bg: 'primary',
        },
        title: 'Plataforma Aberta',
        text:
          'Acreditamos que um fator fundamental para inovação é a transparência e livre trafego de informações. Por isso, disponibilizamos nossas APIs para você integrar os dados da forma como achar melhor.',
        icon: LifeRing,
      },
    ],
  },
  features: {
    title: 'O que é possível fazer com o Abordo',
    text:
      'Seja pelo Site (com versão para desktop e para smartphones) ou pelo aplicativo, o Abordo oferece as seguintes funcionalidades:',
    features: [
      {
        title: 'Fluxo de Vendas',
        text:
          'Pesquisa, Reserva de Poltrona, Detecção de Fraude, Captura de Pagamento e Confirmação de Venda, Cancelamentos. Tudo isso integrado ao seu back-office.',
        details: <Img src={features1} maxWidth="121.0526315789%" ml="-10.5%" mt="-10.9%" />,
      },
      {
        title: 'Gerenciador de Descontos',
        text:
          'O Gerenciador de descontos permite criar Cupons de Descontos para auxiliar nas estratégias de marketing, ou atrelar os descontos a escolha  de uma passagem de volta para os trechos escolhidos.',
        details: <Img src={features2} maxWidth="121.0526315789%" ml="-10.5%" mt="-10.9%" />,
      },
      {
        title: 'Serviços Especiais',
        text:
          'Avise aos usuários quais são os serviços que ele pode embarcar diretor no ônibus, ou embarcar em locais alternativos. Tudo isso apenas através de configurações no painel administrativo.',
        details: <Img src={features3} maxWidth="121.0526315789%" ml="-10.5%" mt="-10.9%" />,
      },
      {
        title: 'Cartão de Embarque',
        text:
          'Ao finalizar a compra na plataforma Abordo, o seu cliente terá acesso ao Cartão de Embarque com QR Code via site ou aplicativo.',
        details: <Img src={features4} maxWidth="121.0526315789%" ml="-10.5%" mt="-10.9%" />,
      },
      // {
      //   title: 'Monitoramento e Localidades Alternativas',
      //   text:
      //     'Com o serviço adicional do Aplicativo do Motorista, também é possível ',
      //   details: <Img src={features4} maxWidth="121.0526315789%" ml="-10.5%" mt="-10.9%" />,
      // },
    ],
  },
  screenshots: {
    title: 'Screenshots',
    screenshots: [
      {
        ImgProps: {
          src: Screenshot,
          srcSet: `${Screenshot} 1x, ${Screenshot2x} 2x`,
          alt: 'Screenshot',
        },
        title: 'Screenshot name',
        text:
          'Proin consectetur in enim a aliquam. Vivamus lacinia et orci at varius. Nam nisi libero, faucibus non metus consequat, lacinia facilisis orci.',
      },
      {
        ImgProps: {
          src: Screenshot,
          srcSet: `${Screenshot} 1x, ${Screenshot2x} 2x`,
          alt: 'Screenshot',
        },
        title: 'Second screenshot name',
        text:
          'Curabitur ut lectus hendrerit, efficitur ante non, vulputate nibh. Aenean luctus consequat nunc id aliquam. Praesent pellentesque.',
      },
      {
        ImgProps: {
          src: Screenshot,
          srcSet: `${Screenshot} 1x, ${Screenshot2x} 2x`,
          alt: 'Screenshot',
        },
        title: 'Third screenshot name',
        text:
          'Vestibulum id tempor neque, viverra posuere nulla. Nulla sagittis augue id pretium finibus. Praesent vel lacinia velit. Suspendisse ornare porta.',
      },
    ],
  },
  clients: {
    title: 'See what other people say about us',
    avatar: {
      src: Avatar,
      srcSet: `${Avatar} 1x, ${Avatar2x} 2x`,
      alt: 'Avatar',
    },
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla mi sit amet malesuada euismod. Morbi pulvinar mauris et erat hendrerit blandit. Duis euismod lectus in eros varius, sed mattis leo posuere. In dapibus semper orci et convallis. Cras posuere dui at turpis viverra fermentum.',
    author: 'Courtney Pena, Los Angeles',
  },
  pricing: {
    title: 'Pricing',
    prices: {
      currency: 'USD',
      types: {
        a: 'Monthly',
        b: 'Annually',
      },
      sections: [
        {
          title: 'Beginner',
          price: 'Free',
          period: 'Forever',
          features: [
            {
              text: 'user',
              prefix: '1',
            },
            {
              text: 'clients',
              prefix: '2',
            },
            {
              text: 'projects',
              prefix: '5',
            },
            {
              text: 'tasks',
              prefix: 'No',
            },
            {
              text: 'subtasks',
              prefix: 'No',
            },
            {
              text: 'invoicing',
              prefix: 'No',
            },
            {
              text: 'estimating',
              prefix: 'No',
            },
          ],
          button: {
            text: 'Try now',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Advanced',
          price: '$25',
          period: 'per month',
          features: [
            {
              text: 'user',
              prefix: '1',
            },
            {
              text: 'clients',
              prefix: 'Unlimited',
            },
            {
              text: 'projects',
              prefix: 'Unlimited',
            },
            {
              text: 'tasks',
              prefix: 'Unlimited',
            },
            {
              text: 'subtasks',
              prefix: 'No',
            },
            {
              text: 'invoices per month',
              prefix: '10',
            },
            {
              text: 'estimates per month',
              prefix: '10',
            },
          ],
          button: {
            text: 'Try now',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Expert',
          price: '$99',
          period: 'per month',
          features: [
            {
              text: 'user',
              prefix: '25',
            },
            {
              text: 'clients',
              prefix: 'Unlimited',
            },
            {
              text: 'projects',
              prefix: 'Unlimited',
            },
            {
              text: 'tasks',
              prefix: 'Unlimited',
            },
            {
              text: 'subtasks',
              prefix: 'Unlimited',
            },
            {
              text: 'invoicing',
              prefix: 'Unlimited',
            },
            {
              text: 'estimating',
              prefix: 'Unlimited',
            },
          ],
          button: {
            text: 'Try now',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
      ],
    },
  },
  cta: {
    title: 'Quer saber mais?',
    button: {
      label: 'Entre em Contato',
      href:
        'mailto:abordo@dex.company',
    },
  },
  socialLinks: [
    {
      icon: FacebookF,
      href: 'https://mypags.app',
      title: 'Facebook',
    },
    {
      icon: Instagram,
      href: 'https://mypags.app',
      title: 'Instagram',
    },
    {
      icon: Youtube,
      href: 'https://mypags.app',
      title: 'YouTube',
    },
  ],
  footer: {
    section1: {
      text: `Nossa missão é empoderar as empresas de transporte rodoviário de passageiros com tecnologia para navegarem no mundo digital.`,
    },
    contact: {
      title: 'Contato',
      sections: [
        {
          icon: MapMarker,
          text: textToMultiline(`Av. Brig. Faria Lima, 1690\nSão Paulo, SP\nBrasil`),
        },
        {
          icon: Phone,
          text: '+55 (11) 9 7218-6303',
        },
        {
          icon: Envelope,
          text: 'abordo@dex.company',
        },
      ],
    },
    section3: {
      title: 'Nosso Sonho Grande',
      text: `Nosso sonho é ser a maior, melhor e mais amada empresa de tecnologia no setor de transporte rodoviário de passageiros do Brasil. Quer saber como vamos chegar lá?`,
      cta: {
        text: 'Entre em Contato',
        href: 'mailto:abordo@dex.company',
      },
    },
  },
  copyright: `© ${new Date().getFullYear()} DEX.Company`,
};
