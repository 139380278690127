import React from 'react';

export default function PaperAirplane(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path
        d="M10.2937 15.0975C10.0157 15.3753 9.83855 15.738 9.79031 16.128C9.74207 16.518 9.82555 16.9129 10.0275 17.25L12.4837 21.345C12.7105 21.7168 13.0385 22.0163 13.4293 22.2084C13.8201 22.4005 14.2576 22.4773 14.6905 22.4296C15.1234 22.382 15.5337 22.212 15.8735 21.9396C16.2132 21.6671 16.4682 21.3035 16.6087 20.8913L22.3612 3.64125C22.4543 3.36182 22.4678 3.06199 22.4001 2.77535C22.3324 2.48871 22.1862 2.22658 21.9779 2.01831C21.7697 1.81005 21.5075 1.66388 21.2209 1.59618C20.9342 1.52848 20.6344 1.54193 20.355 1.635L3.10498 7.38751C2.69277 7.52799 2.32913 7.78304 2.05667 8.12277C1.78421 8.46251 1.61421 8.87286 1.5666 9.30574C1.51898 9.73862 1.59571 10.1761 1.78781 10.5669C1.97991 10.9578 2.27941 11.2858 2.65123 11.5125L6.74998 13.9725C7.08748 14.1754 7.4832 14.2594 7.87401 14.2111C8.26482 14.1629 8.62822 13.9851 8.90623 13.7063L13.6425 8.97001C13.7317 8.87078 13.8401 8.79076 13.9613 8.73483C14.0824 8.67889 14.2137 8.64822 14.347 8.64467C14.4804 8.64112 14.6131 8.66478 14.737 8.71419C14.861 8.76361 14.9736 8.83775 15.0679 8.93209C15.1622 9.02643 15.2364 9.139 15.2858 9.26294C15.3352 9.38687 15.3589 9.51957 15.3553 9.65295C15.3518 9.78632 15.3211 9.91758 15.2652 10.0387C15.2092 10.1598 15.1292 10.2683 15.03 10.3575L10.2937 15.0975Z"
        fill="currentColor"
      />
    </svg>
  );
}
