import React from 'react';

export default function Figma(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 30C12.1728 30 13.6667 28.5061 13.6667 26.6667V23.3333H10.3333C8.4939 23.3333 7 24.8272 7 26.6667C7 28.5061 8.4939 30 10.3333 30ZM10.3333 21.3333C7.38933 21.3333 5 23.7227 5 26.6667C5 29.6107 7.38933 32 10.3333 32C13.2773 32 15.6667 29.6107 15.6667 26.6667V21.3333H10.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 12.6671C8.49386 12.6671 7 14.161 7 16.0004C7 17.8397 8.49386 19.3336 10.3333 19.3336H13.6667V12.6671H10.3333ZM10.3333 10.6671C7.38933 10.6671 5 13.0564 5 16.0004C5 18.9443 7.38933 21.3333 10.3333 21.3333H15.6667V10.6671H10.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 2C8.49394 2 7 3.49391 7 5.33343C7 7.17295 8.49394 8.66686 10.3333 8.66686H13.6667V2H10.3333ZM10.3333 0C7.38933 0 5 2.38938 5 5.33343C5 8.27748 7.38933 10.6671 10.3333 10.6671H15.6667V0H10.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 2H17.6665V8.66686H20.9999C22.8394 8.66686 24.3333 7.17293 24.3333 5.33343C24.3333 3.49393 22.8394 2 20.9999 2ZM15.6667 0V10.6671L20.9999 10.6669C23.944 10.6669 26.3333 8.27748 26.3333 5.33343C26.3333 2.38938 23.944 0 20.9999 0H15.6667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 19.3336C22.8395 19.3336 24.3333 17.8397 24.3333 16.0004C24.3333 14.1611 22.8395 12.6671 20.9999 12.6671C19.1604 12.6671 17.6665 14.1611 17.6665 16.0004C17.6665 17.8397 19.1604 19.3336 20.9999 19.3336ZM20.9999 21.3336C23.944 21.3336 26.3333 18.9443 26.3333 16.0004C26.3333 13.0564 23.944 10.6669 20.9999 10.6669C18.0559 10.6669 15.6665 13.0564 15.6665 16.0004C15.6665 18.9443 18.0559 21.3336 20.9999 21.3336Z"
        fill="currentColor"
      />
    </svg>
  );
}
